@import "../widgets/ReactSelectOverrides.css";

.container {
  width: 100%;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }

  & :global(.Select__control) {
    cursor: pointer;
  }

  & :global(.Select__menu) {
    z-index: 1000;
  }
}

.errorMessage {
  color: #e64d5f;
  text-align: right;
}
.errorColor {
  color: #e64d5f;
}
.errorBorder :global(.Select__control) {
  border-color: #e64d5f !important;
}


.arrowHolder {
  position: absolute;
  right: 10px;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.clearIcon {
  margin-right: 5px;
  cursor: pointer;

  &:hover g {
    fill: var(--gray500) !important;
  }
}
@import "../../components/molecules/Popover.css";

.header{
    padding-left: 8px;
}

.table{
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    margin: 5px;
    overflow-y: auto;
    max-height: 156px;

    & .tooltip{
          font-size: 11px !important;
          margin: 0 auto;
          background-color: var(--white);
          color: var(--gray600) ;
          border: 1px solid var(--gray500);
      }

    & .line {
          text-align: left;
          align-items: center;
          display: flex;
      }
    & .destinationName {
        text-align: left;
        width: 80px;
        margin-right: 9px;
        font-weight: 600;
        overflow-x: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
      }
    & .destinationScheme {
            text-align: left;
            width: 190px;
            margin-right: 13px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
      }
    & .destinationStatus {
            text-align: left;
            width: 80px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
      }
    & .iconContainer {
            width: 24px;
            display: inline-flex;
            justify-content: center;
      }
}


.booleanIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

@import "../../components/molecules/Popover.css";

.header{
    padding-left: 8px;
}

.tooltip{
  font-size: 10px;
  margin: 0 auto;
  background-color: var(--white);
  border: 1px solid var(--gray500);
}

.container {
    width: 153px;
    height: fit-content;
    border: solid 1px var(--gray300);
    background-color: var(--white);
}

.line {
    height: 30px;
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 600;
    color: var(--gray600);

    &:hover {
        color: var(--blue300);
        background-color: var(--blue100);
        cursor: pointer;
        .iconContainer {
            fill: var(--blue300);
        }
    }
    .iconContainer {
        width: 18px;
        height: 18px;
        display: inline-flex;
        justify-content: center;
        margin-right: 7px;
        margin-left: 7px;
        fill: var(--gray600);
    }
}


.booleanIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
